import{ initializeApp } from 'firebase/app';
import 'firebase/auth';
import { Auth, getAuth } from 'firebase/auth';

const firebaseConfigPingoo = {
    apiKey: "AIzaSyCafX9-bNXKuUc1N3hTAAmSisFf91jOC8U",
    authDomain: "dev-innovearn.firebaseapp.com",
    databaseURL: "https://dev-innovearn.firebaseio.com",
    projectId: "dev-innovearn",
    storageBucket: "dev-innovearn-pingoo-clinic",
    messagingSenderId: "658832837155",
    appId: "1:658832837155:web:d8673f3e6a2fe86600ff96",
    measurementId: "G-MFCQMMR8F6"
};
const appPingoo =initializeApp(firebaseConfigPingoo,'pingoo');
const authPingoo: Auth = getAuth(appPingoo);

const firebaseConfig = {
    apiKey: "AIzaSyAuk6ZrmNCT8LiGkDtJNzUR04AawTAP3ug",
    authDomain: "pingoo-clinic-dev.firebaseapp.com",
    projectId: "pingoo-clinic-dev",
    storageBucket: "pingoo-clinic-dev.appspot.com",
    messagingSenderId: "756976756248",
    appId: "1:756976756248:web:7535de1da1dced328a856d",
    measurementId: "G-75F4WJ9D9D"
  };
  const appPingooClinic=initializeApp(firebaseConfig);
  const authPingooClinic:Auth = getAuth(appPingooClinic)
  
  
  
  export { authPingoo, authPingooClinic};
